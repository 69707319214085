<template>
  <el-card shadow="never">
    <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="80px"
        class="demo-ruleForm"
    >
      <el-form-item label="标题" prop="title">
        <el-input v-model="ruleForm.title" autocomplete="off"></el-input>
      </el-form-item>

      <el-form-item label="正文" prop="content">
        <div id="edtor"></div>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="addArticle('ruleForm')" style="background: #1d90ff; border:none;float: right" :disabled="this.show">提交</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
import wangeditor from 'wangeditor'
import {baseURL} from '@/utils/ajax.js';
export default {
  data() {
    return {
      show: false,
      editor: null,
      rules: {
        title: [
          {required: true, message: "请输入标题", trigger: "blur"},
        ]
      },
      ruleForm: {
        content: "",
        title: "",
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  methods: {
    addArticle(ruleForm) {
      this.show = true;
      let context = this.editor.txt.html()
      this.$nextTick(() => {
        this.$refs[ruleForm].validateField("street");
      });
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          this.$api.post(
        "/article/save",
        null,
        { content: context, title: this.ruleForm.title },
          (successRes) => {
            console.log(successRes);
            if (successRes.status == 200) {
              this.$message('操作成功');
              
              this.$router.push({name: "articleList"});
            } else {
              this.$message(successRes.message);
            }
          },
          (failureRes) => {
            this.$message('操作失败');
          }
      );
          
        } else {
          console.log("error submit!!");
          this.$message("输入数据不正确！");
          return false;
        }
      });
    },
  },

mounted() {
    const editor = new wangeditor("#edtor");
    editor.config.onchange = function (newHtml) {
      console.log("change 之后最新的 html", newHtml);
    };
      editor.config.uploadImgServer = baseURL+"/img/upload";
    editor.config.uploadFileName = "picture";
   
    (editor.config.uploadImgHooks = {
      customInsert: function (insertImgFn, result) {
        // result 即服务端返回的接口
        console.log("customInsert", result);
        // insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
        insertImgFn(baseURL.replace("/luyao","")+result.data.data[0]);
      },
    });
    editor.create();
    this.editor = editor;
  },
  beforeDestroy() {
    // 调用销毁 API 对当前编辑器实例进行销毁
    this.editor.destroy()
    this.editor = null
  }
};
</script>